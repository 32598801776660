import * as React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export default function StyledSectionHeader({ children, dividerProps, ...restProps }) {
  return (
    <React.Fragment>
      <Typography variant="h6" {...restProps}>
        {children}
      </Typography>
      <Divider {...dividerProps}/>
    </React.Fragment>
  );
}
