import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

const StyledCardElement = styled(Card)(({ theme }) => ({
  [`&.MuiCard-root`]: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main
  }
}));

const StyledCardHeaderElement = styled(CardHeader)(({ theme }) => ({
  [`&.MuiCardHeader-root`]: {
    backgroundColor: '#f5f5f5',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '8px 10px'
  },
  [`& .MuiCardHeader-title`]: {
    fontSize: '16px'
  }
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  [`&.MuiCardActions-root`]: {
    justifyContent: 'flex-end',
    padding: '16px'
  }
}));

export default function StyledCard({ children, title, titleSubheader, titleIcon, titleAction, buttons, ...restProps }) {
  return (
    <StyledCardElement raised {...restProps}>
      {
        title &&
        <StyledCardHeaderElement
          action={titleAction}
          subheader={titleSubheader}
          title={title}
          avatar={
            titleIcon ?
            <Avatar sx={{ width: '30px', height: '30px' }}>
              {
                typeof titleIcon === 'object' ?
                <titleIcon.type fontSize="small" {...titleIcon.props} />
                :
                titleIcon
              }
            </Avatar>
            :
            undefined
          }
        />
      }
      {children}
      {
        buttons && buttons.length > 0 &&
        <StyledCardActions>
          {
            buttons.map((btn) => (
              <Button {...(btn.buttonProps || {})}>{btn.label}</Button>
            ))
          }
        </StyledCardActions>
      }
    </StyledCardElement>
  );
}
