import React from 'react';
import PageHeader from './PageHeader';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

export default function Page({ title, backButton, children, ...restProps }) {
  return (
    <Container maxWidth="lg" {...restProps}>
      <PageHeader title={title} backButton={backButton}/>
      {children}
      <Box sx={{ height: { xs: '15px', sm:'48px' }}}/>
    </Container>
  );
}
