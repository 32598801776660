import React from 'react';
import Grid from '@mui/material/Grid';

export default function StyledMainGrid({rowSpacing, columnSpacing, ...restProps}) {
  let defaultSpacing = '22px';
  let rowSpace = defaultSpacing;
  let colSpace = 0;
  if ( rowSpacing === false ) {
    rowSpace = 0;
  }
  else if ( rowSpacing !== undefined ) {
    rowSpace = rowSpacing;
  }
  if ( !columnSpacing ) {
    colSpace = 0;
  }
  else if ( columnSpacing === true ) {
    colSpace = defaultSpacing;
  }
  else {
    colSpace = columnSpacing;
  }
  return (
    <Grid
      container
      direction="row"
      rowSpacing={rowSpace}
      columnSpacing={colSpace}
      {...restProps}
    />
  );
}
