import React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function PageHeader({ title, backButton }) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box sx={smUp ? { pt: '40px', pb: '40px' } : { pt: '20px', pb: '20px' } }>
      <Typography variant={smUp ? 'h4' : 'h6'} color="inherit" noWrap sx={{ display: 'flex', alignItems: 'center' }}>
        {backButton}
        {
          Array.isArray(title) &&
          title.map((t, idx) => (
            <React.Fragment key={idx}>
              {
                idx > 0 &&
                <ChevronRightIcon sx={{ ml: '5px', mr: '5px', mt: { xs: 0, sm: '3px' } }} fontSize={smUp ? 'large' : 'medium'}/>
              }
              {t}
            </React.Fragment>
          ))
        }
        {
          !Array.isArray(title) &&
          title.toString()
        }
      </Typography>
    </Box>
  );
}
